import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WebFont from 'webfontloader';

import Booking from './pages/booking/Booking';
import AdminDashboard from './pages/dashboard/admin-dashboard/AdminDashboard';
import UserDashboard from './pages/dashboard/user-dashboard/UserDashboard';
import Home from './pages/home/Home';
import Trip from './pages/trip/Trip';
import Login from './pages/login/Login';
// import NotFound from './pages/not-found/NotFound';
import Register from './pages/register/Register';
import Terms from './pages/terms/Terms';
import Vehicles from './pages/vehicles/Vehicles';
import PaymentSuccess from './pages/payment-success/PaymentSuccess';
import Airport from './pages/airport/Airport';
import PrivateRoute from './routes/PrivateRoute';
import { Loader } from './components/styled/Processing';

import './App.css';
import Help from './pages/help/Help';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Gdpr from './pages/gdpr/Gdpr';
import TaxiTransfer from './pages/taxi-transfer/TaxiTransfer';
// import BookingHelp from './pages/help/BookingHelp';

import pjon from '../package.json';
import AdminRoute from './routes/AdminRoute';
import DriversManagement from './pages/dashboard/admin-dashboard/drivers/DriversManagement';

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    console.log({ version: pjon.version, date: '26/09/24' });
    console.log('nothing to see here; have an amazing day (or night) ^^');
  }, []);

  useEffect(() => {
    // Initialize WebFont Loader
    WebFont.load({
      google: {
        families: ['Inter:400,700', 'Saira Condensed:400,700'],
      },
      active: () => {
        // Fonts are loaded and active
        setFontsLoaded(true);
      },
    });
  }, []);

  if (fontsLoaded) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/trip" element={<Trip />} />
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/login" element={<Login />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/airport/:airport" element={<Airport />} />
          <Route path="/taxi-transfer" element={<TaxiTransfer />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/admin-dashboard"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-dashboard/drivers"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <DriversManagement allDrivers />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-dashboard/drivers/add"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <DriversManagement addDriver />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/user-dashboard"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-dashboard/booking/:id"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-dashboard/booking/:id"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    );
  } else
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
}

export default App;
